import React from 'react';

const NotFoundPage = () => {
	return (
		<main>
			<title>Not found</title>
			<h1>Page not found</h1>
		</main>
	);
};

export default NotFoundPage;
